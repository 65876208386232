
// Fonts
//@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
//@import "~bootstrap-sass/assets/stylesheets/bootstrap";

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

/* @import '~datatables.net-dt/css/jquery.dataTables';
@import '~datatables.net-responsive-dt/css/responsive.dataTables';
@import '~datatables.net-rowgroup-dt/css/rowGroup.dataTables';
@import '~datatables.net-buttons-dt/css/buttons.dataTables'; */
@import '~datatables.net-dt/css/jquery.dataTables.css';
@import '~datatables.net-responsive-dt/css/responsive.dataTables.css';
@import '~datatables.net-rowgroup-dt/css/rowGroup.dataTables.css';
@import '~datatables.net-buttons-dt/css/buttons.dataTables.css';

@import '~nprogress/nprogress.css';

@import '~flatpickr/dist/flatpickr.css';

@import '~flatpickr/dist/plugins/confirmDate/confirmDate.css';

@import '~sweetalert2/src/sweetalert2.scss';
@import '~sweetalert2/src/variables.scss';

@import '~animate.css/animate.css';
